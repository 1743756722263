import iconBing from 'data-url:./img/tags/bing.png';
import iconCustom from 'data-url:./img/tags/custom.png';
import iconEtConversion from 'data-url:./img/tags/etConversion.png';
import iconEtDimension from 'data-url:./img/tags/etDimension.png';
import iconEtEvent from 'data-url:./img/tags/etEvent.png';
import iconEtOnsite from 'data-url:./img/tags/etOnsite.png';
import iconGa from 'data-url:./img/tags/ga.png';
import iconGaConversion from 'data-url:./img/tags/gaConversion.png';
import iconLinkedin from 'data-url:./img/tags/linkedin.png';
import iconLinkedinConversion from 'data-url:./img/tags/linkedinConversion.png';
import iconMeta from 'data-url:./img/tags/meta.png';
import iconTiktok from 'data-url:./img/tags/tiktok.png';

export default {
  'bing': iconBing,
  'custom': iconCustom,
  'etConversion': iconEtConversion,
  'etDimension': iconEtDimension,
  'etEvent': iconEtEvent,
  'etOnsite': iconEtOnsite,
  'ga': iconGa,
  'gaConversion': iconGaConversion,
  'linkedin': iconLinkedin,
  'linkedinConversion': iconLinkedinConversion,
  'meta': iconMeta,
  'tiktok': iconTiktok
};